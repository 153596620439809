<template lang="pug">
VDialog.verse-picker-dialog(
  v-model="open"
  v-bind="$attrs"
  :fullscreen="fullScreen"
  :scrollable="true"
  :persistent="true"
  :max-width="fullScreen ? undefined : 700"
  @click:outside="cancel"
)
  template(#activator="activatorProps")
    slot(
      name="activator"
      v-bind="activatorProps"
    )

  VCard
    VCardTitle
      VBtn.mr-2.verse-picker-dialog__cancel-button(
        :icon="true"
        :title="i18n.t('VersePicker.Cancel')"
        :aria-label="i18n.t('VersePicker.Cancel')"
        size="small"
        variant="flat"
        @click="cancel"
      )
        VIcon {{ mdiArrowLeft }}
      span {{ i18n.t("VersePicker.ModalTitle") }}
    VDivider

    VCardText
      VersePickerTextField(
        v-model:range="versePickerModel"
        :verse-picker-tid="versePickerTid"
        :local-tid="localTid"
        :aria-label="i18n.t('VersePicker.VersePicker')"
        @submit="ok"
      )

      // Compensate for the 24px VCardText margin.
      VTabs.verse-picker-dialog__main-tabs.mt-4(
        v-model="mainTab"
        :grow="true"
        :show-arrows="true"
        :center-active="true"
        style="margin-left: -24px; margin-right: -24px"
      )
        VTab.px-2(
          value="history"
          min-width="0"
          :aria-label="i18n.t('VersePicker.History')"
          :title="i18n.t('VersePicker.History')"
        )
          VIcon {{ mdiHistory }}
        VTab(value="books") {{ i18n.t("VersePicker.Books") }}
        VTab(value="chapters") {{ i18n.t("VersePicker.Chapters") }}
        VTab(value="verses") {{ i18n.t("VersePicker.Verses") }}

      VWindow(v-model="mainTab")
        VWindowItem(value="history")
          VersePickerDialogHistory(
            :verse-picker-tid="versePickerTid"
            :local-tid="localTid"
            :history="history"
            @choose="chooseRangeAndClose"
          )

        VWindowItem(value="books")
          VTabs.verse-picker-dialog__testament-tabs.mt-4(
            v-model="testamentTab"
            :grow="true"
            :show-arrows="true"
            :center-active="true"
          )
            VTab(value="ot") {{ i18n.t("VersePicker.OldTestament") }}
            VTab(value="nt") {{ i18n.t("VersePicker.NewTestament") }}

          VWindow(v-model="testamentTab")
            VWindowItem(value="ot")
              // TODO: Show loading state
              VList.verse-picker-dialog__book-list.verse-picker-dialog__book-list-ot
                VListItem(
                  v-for="data in books.value?.ot || []"
                  :key="data.book.bid"
                  :active="versePickerModel?.overlapsWith(data.book)"
                  @click="selectBook(data.book)"
                )
                  VListItemTitle {{ data.name }}

            VWindowItem(value="nt")
              // TODO: Show loading state
              VList.verse-picker-dialog__book-list.verse-picker-dialog__book-list-nt
                VListItem(
                  v-for="data in books.value?.nt || []"
                  :key="data.book.bid"
                  :active="versePickerModel?.overlapsWith(data.book)"
                  @click="selectBook(data.book)"
                )
                  VListItemTitle {{ data.name }}

        VWindowItem(value="chapters")
          VList(v-if="chaptersInSelectedBook.isPending")
            VListItem {{ i18n.t("VersePicker.Loading") }}
          VList.verse-picker-dialog__chapter-list(
            v-else-if="chaptersInSelectedBook.value?.length"
          )
            VListItem.px-1.text-center(
              v-for="chapterInfo in chaptersInSelectedBook.value"
              :key="chapterInfo.number"
              :active="versePickerModel?.overlapsWith(chapterInfo)"
              @click="selectChapter(chapterInfo)"
            ) {{ chapterInfo.number }}
          VList(v-else)
            VListItem {{ i18n.t("VersePicker.SelectBookFirst") }}

        VWindowItem(value="verses")
          VList(v-if="versesInSelectedChapter.isPending")
            VListItem {{ i18n.t("VersePicker.Loading") }}
          VList.verse-picker-dialog__verse-list(
            v-else-if="versesInSelectedChapter.value?.length"
          )
            VListItem.px-1.text-center(
              v-for="verseInfo in versesInSelectedChapter.value"
              :key="verseInfo.label"
              :active="versePickerModel?.overlapsWith({ fromVid: verseInfo.vid, toVid: verseInfo.vid })"
              @click="selectVerse(verseInfo.vid)"
            ) {{ verseInfo.label }}
          VList(v-else)
            VListItem {{ i18n.t("VersePicker.SelectChapFirst") }}

    VDivider
    VCardActions
      VSpacer
      VBtn.verse-picker-dialog__ok-button(
        :icon="true"
        :title="i18n.t('VersePicker.ok')"
        :aria-label="i18n.t('VersePicker.ok')"
        size="x-large"
        @click="ok"
      )
        VIcon {{ mdiCheck }}
</template>

<script setup lang="ts">
import {
  getBooksInForeignAndLocalTranslation,
  ntBids,
  otBids,
  VidRange,
} from "@rsc/scripture-util";
import { computed, type Ref, ref } from "vue";
import {
  type StandaloneBookData,
  type StandaloneChapterData,
  type VerseIdAndLabel,
} from "@rsc/scripture-model";
import { useI18n } from "vue-i18n";
import { mdiArrowLeft, mdiCheck, mdiHistory } from "@mdi/js";
import { type ScriptureClientError } from "@rsc/scripture-client";
import { okAsync, ResultAsync } from "neverthrow";
import { useDisplay } from "vuetify";
import { useComputedResultAsync } from "~/composables/useComputedResultAsync";
import { injectRequired } from "~/injectRequired";
import { ScriptureClientKey } from "~/injectionKeys";

const i18n = useI18n();

const scriptureClient = injectRequired(ScriptureClientKey);

interface Props {
  /**
   * The ID of the translation from which to select verses.
   */
  versePickerTid: string;

  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;

  /**
   * The currently selected range.
   */
  range?: VidRange;

  /**
   * Previously selected ranges.
   */
  history?: VidRange[];

  /**
   * Whether the dialog is open.
   */
  open?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  history: () => [],
});

interface Emits {
  (e: "update:range", value: VidRange): void;
  (e: "update:open", value: boolean): void;
}

const emit = defineEmits<Emits>();

const display = useDisplay();

const fullScreen = computed<boolean>(() => display.xs.value);

type MainTabName = "history" | "books" | "chapters" | "verses";

/**
 * Model for the main tabs.
 */
const mainTab = ref<MainTabName>("books");

type TestamentTabName = "ot" | "nt";

/**
 * Model for the tabs to select testament.
 */
const testamentTab = ref<TestamentTabName>("ot");

/**
 * Model for the dialog's opened/closed state.
 */
const open = computed<boolean>({
  get() {
    return props.open;
  },
  set(value) {
    emit("update:open", value);
  },
});

/**
 * Model for the verse picker text field.
 */
const versePickerModel = ref<VidRange | undefined>(props.range) as Ref<
  VidRange | undefined
>;

// Update the verse picker model when the dialog opens.
watch(open, (value) => {
  if (value) {
    versePickerModel.value = props.range;
  }
});

type BookWithLocalBook = {
  book: StandaloneBookData;
  localBook: StandaloneBookData;
  name: string;
};

/**
 * All books, both in the verse picker translation and in the local translation.
 */
const books = useComputedResultAsync<
  {
    all: BookWithLocalBook[];
    ot: BookWithLocalBook[];
    nt: BookWithLocalBook[];
  },
  ScriptureClientError
>(() => {
  const all = getBooksInForeignAndLocalTranslation(
    scriptureClient,
    props.versePickerTid,
    props.localTid,
  );

  const ot = all.map((books) =>
    books.filter((book) => otBids.includes(book.book.bid)),
  );

  const nt = all.map((books) =>
    books.filter((book) => ntBids.includes(book.book.bid)),
  );

  return ResultAsync.combine([all, ot, nt]).map(([all, ot, nt]) => ({
    all,
    ot,
    nt,
  }));
});

/**
 * The selected book. We don't allow ranges covering multiple books.
 */
const selectedBook = computed<BookWithLocalBook | null>(
  () =>
    (books.value?.all ?? []).find((data) =>
      versePickerModel.value?.overlapsWith(data.book),
    ) || null,
);

const chaptersInSelectedBook = useComputedResultAsync<
  StandaloneChapterData[],
  ScriptureClientError
>(() => {
  const bid = selectedBook.value?.book?.bid;
  if (!props.versePickerTid || !bid) {
    return okAsync([]);
  }

  return scriptureClient.getChapters(props.versePickerTid, bid);
});

/**
 * The ordinal of the selected chapter.
 */
const selectedChapterNumber = computed<number | null>(() => {
  const chapterData = (chaptersInSelectedBook.value ?? []).find((data) =>
    versePickerModel.value?.overlapsWith(data),
  );
  return chapterData?.number || null;
});

const versesInSelectedChapter = useComputedResultAsync<
  VerseIdAndLabel[],
  ScriptureClientError
>(() => {
  const bid = selectedBook.value?.book?.bid;
  const chapter = selectedChapterNumber.value;
  if (!bid || !chapter) {
    return okAsync([]);
  }

  return scriptureClient.getVerseIdsAndLabels(
    props.versePickerTid,
    bid,
    chapter,
  );
});

/**
 * The user clicked on a book.
 */
function selectBook(book: StandaloneBookData): void {
  versePickerModel.value = new VidRange(book.fromVid, book.toVid, book.tid);
  mainTab.value = "chapters";
}

/**
 * The user clicked on a chapter.
 */
function selectChapter(chapterInfo: {
  number: number;
  fromVid: number;
  toVid: number;
}): void {
  versePickerModel.value = new VidRange(
    chapterInfo.fromVid,
    chapterInfo.toVid,
    props.versePickerTid,
  );
  mainTab.value = "verses";
}

/**
 * The use clicked on a verse.
 */
function selectVerse(vid: number): void {
  // TODO: Take compound verses into account here?
  versePickerModel.value = new VidRange(vid, vid, props.versePickerTid);
  ok();
}

function chooseRangeAndClose(range: VidRange): void {
  versePickerModel.value = range;
  ok();
}

function cancel(): void {
  // Throw away the changes.
  versePickerModel.value = props.range;
  close();
}

function ok(): void {
  if (versePickerModel.value instanceof VidRange) {
    // Emit changes to the parent.
    emit("update:range", versePickerModel.value);
    close();
  }
}

function close(): void {
  open.value = false;
  mainTab.value = "books";
}
</script>

<style lang="scss" scoped>
.verse-picker-dialog__book-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.verse-picker-dialog__chapter-list,
.verse-picker-dialog__verse-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
}
</style>
